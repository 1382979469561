import React, { useContext } from 'react';
import dynamic from 'next/dynamic';

// types
import type { WCmsHomepageView } from '@zola/svc-web-api-ts-client';
import type { SinglePageHeroVariantProps } from 'components/publicWebsiteV2/common/SinglePageHeroContainer/types';

// utils
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';
import mapFontValuesToComponent from 'components/publicWebsiteV2/util/mappers/mapFontValuesToComponent';
import { getFontSizeOverride } from 'components/publicWebsiteV2/pageLayouts/SinglePageLayout/helpers/responsiveFontSizeHelpers';
import useWindowDimensions from 'components/publicWebsiteV2/util/useWindowDimensions';
import { filterHeroPhotos } from 'components/manage/EditWebsite/common/HeroCustomizer/utils';
import { FoilAnimationWrapper } from 'components/publicWebsiteV2/common/FoilAnimation/FoilAnimationWrapper/FoilAnimationWrapper';

// context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { DeviceContext } from 'contexts/DeviceContext';

// styles
import { Title, TitleInnerContainer } from './SinglePageHeroContainer.styles';
import { PageAnimationWrapper } from '../PageAnimationWrapper/PageAnimationWrapper';
import { FoilAnimationProvider } from '../FoilAnimation/FoilAnimationContext/FoilAnimationProvider';

// dynamic import components
const SinglePageHeroSingleImage = dynamic(() => import('./components/SinglePageHeroSingleImage'));
const SinglePageHeroSlideshow = dynamic(() => import('./components/SinglePageHeroSlideshow'));

export type MultiPageHeroContainerProps = SinglePageHeroVariantProps & {
  pageData?: WCmsHomepageView;
  isSamplePage?: boolean;
};

const SinglePageHeroContainer: React.FC<MultiPageHeroContainerProps> = ({
  pageData,
  previewContainerHeight,
  isSamplePage,
}) => {
  const {
    state: {
      wedding,
      components: { globalHeaderFontValues },
      inPreview,
    },
  } = useWebsiteThemeContext();

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const { device } = useContext(DeviceContext);

  const { public_theme_v2 } = wedding || {};

  const { title, hero_image_layout_type } = pageData || {};

  const { fonts } = public_theme_v2?.components?.HERO_HOME || {};
  const heroHeaderFontStyles = fonts?.HEADER?.[1];
  const StyledTitle = mapFontValuesToComponent({
    fallback: globalHeaderFontValues,
    partial: { ...heroHeaderFontStyles, color: 'FFFFFF' },
    mediaQuery: MEDIA_QUERY,
    excludeFontSize: true,
    ComponentType: Title,
  });
  const getWelcomeMessageDesktopFontSize = () => {
    const aspectRatio = windowWidth / windowHeight;
    if (aspectRatio >= 3) {
      return 3.5;
    }
    if (aspectRatio >= 2) {
      return 4;
    }
    if (aspectRatio >= 1.5) {
      return 4.5;
    }
    return 5;
  };
  const welcomeMessageFontSizeOverride = getFontSizeOverride(
    {
      desktop: getWelcomeMessageDesktopFontSize(),
      tablet: globalHeaderFontValues?.isCursive ? 11 : 8,
      mobile: globalHeaderFontValues?.isCursive ? 11 : 8,
    },
    inPreview
  );

  const heroImageLayoutType = hero_image_layout_type
    ? `${hero_image_layout_type}`
    : 'SINGLE_IMAGE_SINGLE_PAGE';

  const isDesktop = inPreview === 'DESKTOP' || (!inPreview && !!device?.isDesktop());

  const renderTitleComponent = () => {
    if (!title) return null;

    return (
      <StyledTitle fontSizeOverride={welcomeMessageFontSizeOverride}>
        <TitleInnerContainer>
          <FoilAnimationWrapper text={title} />
        </TitleInnerContainer>
      </StyledTitle>
    );
  };

  // Filter photos by layout
  const imagesFilteredByLayout = filterHeroPhotos(heroImageLayoutType, isDesktop, pageData?.images);

  const LayoutComponent =
    heroImageLayoutType === 'SLIDESHOW_SINGLE_PAGE' ? (
      <SinglePageHeroSlideshow
        images={imagesFilteredByLayout}
        inPreview={inPreview}
        previewContainerHeight={previewContainerHeight}
      >
        {renderTitleComponent()}
      </SinglePageHeroSlideshow>
    ) : (
      <SinglePageHeroSingleImage
        images={imagesFilteredByLayout}
        inPreview={inPreview}
        previewContainerHeight={previewContainerHeight}
        isSamplePage={isSamplePage}
      >
        {renderTitleComponent()}
      </SinglePageHeroSingleImage>
    );

  return (
    <div data-testid="SinglePageHeroContainer">
      <PageAnimationWrapper>
        <FoilAnimationProvider>{LayoutComponent}</FoilAnimationProvider>
      </PageAnimationWrapper>
    </div>
  );
};

export default SinglePageHeroContainer;
