import type {
  WPublicThemeV2View,
  WThemeComponentView,
  WOptionTypeValueView,
  WPublicWeddingView,
} from '@zola/svc-web-api-ts-client';

const transformComponentOrientation = (
  components: WPublicThemeV2View['components']
): WPublicThemeV2View['components'] => {
  const result: WPublicThemeV2View['components'] = {};
  for (const componentKey in components) {
    if (Object.prototype.hasOwnProperty.call(components, componentKey)) {
      const currentComponent = components[componentKey];
      if (currentComponent?.option_type_values?.ORIENTATION?.value === 'HORIZONTAL') {
        const updatedComponent: WThemeComponentView = {
          ...currentComponent,
          // updated option types
          option_type_values: {
            ...currentComponent?.option_type_values,
            ORIENTATION: {
              option_type: ('ORIENTATION' as unknown) as WOptionTypeValueView.OptionTypeEnum,
              value: 'VERTICAL',
            },
          },
        };
        result[componentKey] = updatedComponent;
      } else {
        result[componentKey] = currentComponent;
      }
    }
  }
  return result;
};

// also adjusting layout_type
const transformWeddingThemeComponents = (
  w: WPublicWeddingView | undefined
): WPublicWeddingView | undefined => {
  const publicThemeObj = w?.public_theme_v2;
  const componentsObj = w?.public_theme_v2?.components;
  if (!publicThemeObj || !componentsObj) return w;
  const updatedPublicTheme = {
    ...w?.public_theme_v2,
    layout_type: ('SINGLE_PAGE' as unknown) as WPublicThemeV2View.LayoutTypeEnum,
  } as WPublicThemeV2View;
  updatedPublicTheme.components = transformComponentOrientation(componentsObj);
  return {
    ...w,
    public_theme_v2: updatedPublicTheme,
  };
};

export default transformWeddingThemeComponents;
