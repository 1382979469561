import styled from '@emotion/styled';
import type { WInFlowThemeComponentAssetSummaryView } from '@zola/svc-web-api-ts-client';
import { mapInFlowAssetToCSS } from 'components/publicWebsiteV2/util/mappers';
import InnerContainer from 'components/publicWebsiteV2/common/InnerContainer';
import {
  OuterDoubleBorder,
  InnerDoubleBorder,
  StandardContainer,
  ScoopedContainer,
} from 'components/publicWebsiteV2/common/InnerContainer/InnerContainer.styles';
import { MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';
import NamesModule from 'components/publicWebsiteV2/common/NamesModule';
import { ChevronDownIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronDown';
import { css, keyframes } from '@emotion/react';

import { Z_INDEX_NAV_KEY, Z_INDEX_MAP } from 'components/publicWebsiteV2/util/zIndexConstants';
import type { DevicePartial } from 'components/publicWebsiteV2/util/getRelativeImgSize';
import { NON_ADJUSTED_RANGE } from './helpers/transformMediaQuery';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr;
  position: relative;
  height: 100%;

  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    grid-template-columns: 1fr;
  }
`;

export const RightPanel = styled.div<{
  overrideHeight?: number;
  inPreview?: boolean;
}>`
  width: 100%;
  height: ${({ overrideHeight }) => (overrideHeight ? `${overrideHeight}px` : '100vh')};
  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    height: unset;
  }
  overflow: hidden;
  position: relative;

  ${({ inPreview }) => (inPreview ? `pointer-events: auto;` : '')}
  ${NON_ADJUSTED_RANGE} {
    width: 100%;
    margin-left: 0;
    position: relative;
  }
`;

export const RightPanelScrollable = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    overflow: hidden;
  }
`;

export const StyledInnerContainer = styled(InnerContainer)`
  height: 100%;
`;

export const PrimaryContentContainer = styled.div`
  position: relative;
  // prevent collapsing margin
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: -1px;
  margin-bottom: -1px;
`;

export const SinglePageNamesModule = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NAMES_MODULE_SPACING_PERCENTAGE_WITH_ASSETS = 15;
export const getNamesModuleSpacing = (reserveAssetSpacing?: boolean) =>
  reserveAssetSpacing ? NAMES_MODULE_SPACING_PERCENTAGE_WITH_ASSETS : 0;

const NAMES_MODULE_SPACING_PERCENTAGE_Y_MOBILE = 40;

export const StyledNamesModule = styled(NamesModule)<{ reserveAssetSpacing?: boolean }>`
  margin: ${({ reserveAssetSpacing }) => getNamesModuleSpacing(reserveAssetSpacing)}%;

  ${({ theme }) => theme.MEDIA_QUERY.TABLET} {
    margin: ${({ reserveAssetSpacing }) =>
      reserveAssetSpacing
        ? NAMES_MODULE_SPACING_PERCENTAGE_Y_MOBILE
        : getNamesModuleSpacing(reserveAssetSpacing)}%;
  }

  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    margin: ${({ reserveAssetSpacing }) =>
      reserveAssetSpacing
        ? NAMES_MODULE_SPACING_PERCENTAGE_Y_MOBILE
        : getNamesModuleSpacing(reserveAssetSpacing)}%;
  }
`;
export const WELCOME_NAMES_MIN_HEIGHT_DESKTOP = 600;
export const WELCOME_NAMES_MIN_HEIGHT_TABLET = 500;
export const WELCOME_NAMES_MIN_HEIGHT_MOBILE = 480;
export const WELCOME_NAMES_MIN_HEIGHT_MOBILE_XS = 360;

const getHeightStyles = (minHeight: string, overrideHeight?: number) => `
  height: unset;
  min-height: ${overrideHeight ? `${overrideHeight}px` : `${minHeight}`};
`;
export const FullHeightContainer = styled.div<{ overrideHeight?: number }>`
  ${({ overrideHeight }) => getHeightStyles('100vh', overrideHeight)}
  ${({ theme, overrideHeight }) =>
    overrideHeight ? theme.MEDIA_QUERY.TABLET : MEDIA_QUERY.TABLET} {
    ${({ overrideHeight }) =>
      getHeightStyles(`${WELCOME_NAMES_MIN_HEIGHT_TABLET}px`, overrideHeight)};
  }
  ${({ theme, overrideHeight }) =>
    overrideHeight ? theme.MEDIA_QUERY.MOBILE : MEDIA_QUERY.MOBILE} {
    ${({ overrideHeight }) =>
      getHeightStyles(`${WELCOME_NAMES_MIN_HEIGHT_MOBILE}px`, overrideHeight)};
  }
  ${({ theme, overrideHeight }) =>
    overrideHeight ? theme.MEDIA_QUERY.MOBILE_XS : MEDIA_QUERY.MOBILE_XS} {
    ${({ overrideHeight }) =>
      getHeightStyles(`${WELCOME_NAMES_MIN_HEIGHT_MOBILE_XS}px`, overrideHeight)};
  }
  display: flex;
  flex-direction: column;
  position: relative;

  ${OuterDoubleBorder} {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  ${InnerDoubleBorder} {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  ${StandardContainer} {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  ${ScoopedContainer} {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const bounce = keyframes`
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

export const ScrollDownArrow = styled(ChevronDownIcon)<{
  disableAnimation?: boolean;
}>`
  position: absolute;
  bottom: 10%;
  margin-left: auto;
  margin-right: auto;
  ${({ disableAnimation }) =>
    disableAnimation
      ? ''
      : css`
          animation: ${bounce} 2s 3;
        `}

  &:hover {
    cursor: pointer;
  }
  ${({ inMobilePreview, theme }) =>
    inMobilePreview ? theme.MEDIA_QUERY.EXCLUDE_DESKTOP : MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: none;
  }
`;

export const SectionContainer = styled.div<{ addTopPadding?: boolean }>`
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ theme, addTopPadding }) => (addTopPadding ? theme.SPACING.XXL : '0px')};
`;

export const SectionDivider = styled.div<{
  inFlowAsset?: WInFlowThemeComponentAssetSummaryView;
  device?: DevicePartial;
  containerWidth?: number;
}>`
  padding-bottom: ${({ theme }) => theme.SPACING.XL};
  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    padding-bottom: ${({ theme }) => theme.SPACING.LG};
  }
  ${({ inFlowAsset, device, containerWidth, theme }) =>
    mapInFlowAssetToCSS(inFlowAsset, device, containerWidth, theme.MEDIA_QUERY)}
`;

export const HeaderSPContainer = styled.div<{ inPreview?: string }>`
  left: 0;
  position: ${({ inPreview }) => (inPreview && inPreview === 'MOBILE' ? 'unset' : 'fixed')};
  right: 0;
  top: 0;
  z-index: ${Z_INDEX_MAP[Z_INDEX_NAV_KEY]};
`;
