import styled from '@emotion/styled';

import { SPACING } from '@zola/zola-ui/src/styles/emotion';
import { SideDrawerHeaderContainer } from '@zola/zola-ui/src/components/SideDrawer';

export const FlyoutHeader = styled(SideDrawerHeaderContainer)`
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  margin: 0;
  padding: ${SPACING.S24} ${SPACING.MD};
  position: sticky;
  top: 0;
`;

export const FlyoutBody = styled.div<{ overrideHeight?: boolean }>`
  margin: 0 ${SPACING.MD};
  max-height: 90%;
  overflow: auto;
`;
