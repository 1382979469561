import React, { useState, useRef } from 'react';
import type { WPublicNavItemView } from '@zola/svc-web-api-ts-client';
import { ShoppingCartIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ShoppingCart';
import { Menu2Icon } from '@zola/zola-ui/src/components/SvgIconsV3/Menu2';
import useClickOutside from '@zola/zola-ui/src/hooks/useClickOutside';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import {
  StyledCaretIcon,
  HeaderContainer,
  IconContainer,
  MenuIconContainer,
  NavLinkContainer,
  NavLinkItem,
  DesktopMenuIconButton,
  MobileMenuIconButton,
  NavLinkInnerContainer,
} from './NavigationMenuIcon.styles';
import HeaderCart from '../Header/HeaderCart';

type NavigationMenuIconProps = {
  className?: string;
  handleNavLinkClick: (pageType: string) => void;
  navItems?: WPublicNavItemView[];
  previewContainerHeight?: number;
};

const NavigationMenuIcon = ({
  className,
  handleNavLinkClick,
  navItems,
  previewContainerHeight,
}: NavigationMenuIconProps): JSX.Element => {
  const {
    state: { inPreview, enableInPreviewInteractions },
  } = useWebsiteThemeContext();

  const [expanded, setExpanded] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const handleClose = () => setExpanded(false);

  useClickOutside({
    ref,
    onClickOutside: handleClose,
    isOpen: expanded,
  });

  return (
    <HeaderContainer ref={ref} className={className}>
      {(navItems || []).length > 0 && (
        <MenuIconContainer onClick={() => setExpanded(!expanded)}>
          <DesktopMenuIconButton>
            <StyledCaretIcon width={24} height={24} direction={expanded ? 'up' : 'down'} />
            Menu
          </DesktopMenuIconButton>
          <MobileMenuIconButton>
            <Menu2Icon width={24} height={24} />
          </MobileMenuIconButton>
          {expanded && (
            <NavLinkContainer previewContainerHeight={previewContainerHeight}>
              <NavLinkInnerContainer>
                {navItems?.map((n, i) => {
                  const typeLower = ((n?.type as unknown) as string)?.toLowerCase();
                  return (
                    <NavLinkItem
                      key={`nav-menu-expanded-item-${i}`}
                      onClick={e => {
                        if (inPreview && enableInPreviewInteractions?.nav) {
                          e.preventDefault();
                          enableInPreviewInteractions.nav(typeLower);
                          return;
                        }
                        handleNavLinkClick(typeLower);
                      }}
                    >
                      {n?.nav_title}
                    </NavLinkItem>
                  );
                })}
              </NavLinkInnerContainer>
            </NavLinkContainer>
          )}
        </MenuIconContainer>
      )}
      <HeaderCart
        isDarkMode={false}
        cartIconUI={
          <IconContainer>
            <ShoppingCartIcon width={24} height={24} />
          </IconContainer>
        }
      />
    </HeaderContainer>
  );
};

export default NavigationMenuIcon;
