import styled from '@emotion/styled';
import COLORS3, { COLORS_BRAND, COLORS_NEUTRALS } from '@zola/zola-ui/src/styles/emotion/colors3';
import { ChevronDownIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronDown';
import { Z_INDEX_NAV_KEY, Z_INDEX_MAP } from 'components/publicWebsiteV2/util/zIndexConstants';

export const IconContainer = styled.div`
  border-radius: 100px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  background-color: ${COLORS_BRAND.BOLD_CLOUD_100};
  color: ${COLORS_NEUTRALS.BLACK_100};
  height: 56px;
  font-weight: ${({ theme }) => theme.FONTS.FONT_WEIGHT_BOLD};
  font-size: 16px;
  &:hover {
    cursor: pointer;
    background-color: ${COLORS3.BLACK_005};
  }
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export const StyledCaretIcon = styled(ChevronDownIcon)`
  margin-right: 8px;
`;

export const HeaderContainer = styled.div`
  position: absolute;
  z-index: ${Z_INDEX_MAP[Z_INDEX_NAV_KEY]};
  display: flex;
  padding: ${({ theme }) => theme.SPACING.MD};

  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    right: 0;
    padding: ${({ theme }) => theme.SPACING.SM};
    flex-direction: row-reverse;
  }
`;

export const MenuIconContainer = styled.div`
  position: relative;
`;

export const DesktopMenuIconButton = styled(IconContainer)`
  display: flex;
  margin-right: 16px;
  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: none;
  }
`;

export const MobileMenuIconButton = styled(IconContainer)`
  display: none;
  margin-left: 16px;
  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: flex;
  }
`;

export const NavLinkInnerContainer = styled.div`
  overflow-y: scroll;
  padding: 20px;
`;

export const NavLinkContainer = styled.div<{ previewContainerHeight?: number }>`
  position: absolute;
  border-radius: 16px;
  min-width: 230px;
  top: calc(100% + 16px);
  max-height: calc(
    ${({ previewContainerHeight }) =>
        previewContainerHeight ? `${Math.ceil(previewContainerHeight)}px` : '100vh'} - 150px
  );
  overflow: hidden;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS3.WHITE_100};
  color: ${COLORS_NEUTRALS.BLACK_100};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.FONTS.FONT_WEIGHT_BOLD};
  z-index: 1;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    right: 0;
    left: unset;
  }
`;

export const NavLinkItem = styled.div`
  padding: 8px;
  &:hover {
    cursor: pointer;
  }
`;
