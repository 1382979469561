import MEDIA_QUERY, {
  SCREEN_XS,
  SCREEN_SM,
  SCREEN_MD,
  SCREEN_LG,
  SCREEN_XL,
  SCREEN_MASSIVE,
} from '@zola/zola-ui/src/styles/emotion/mediaQuery';

export const RIGHT_PANEL_PERCENTAGE_VALUE = 39;
const ADJUSTMENT_BREAKPOINT = SCREEN_MD;
// NOTE: Using static media query
export const NON_ADJUSTED_RANGE = MEDIA_QUERY.EXCLUDE_DESKTOP;

const mapScreenSize = (size: number) => Math.ceil((size * 100) / RIGHT_PANEL_PERCENTAGE_VALUE);

/**
 * Transform media query ranges for the right panel to mimic a container query
 */
const transformMediaQuery = () => {
  const SCREEN_XS_SP = mapScreenSize(SCREEN_XS); // 1231px
  const SCREEN_SM_SP = mapScreenSize(SCREEN_SM); // 1970px
  const SCREEN_MD_SP = mapScreenSize(SCREEN_MD); // 2544px
  const SCREEN_LG_SP = mapScreenSize(SCREEN_LG); // 3077px
  const SCREEN_XL_SP = mapScreenSize(SCREEN_XL); // 3693px
  const SCREEN_MASSIVE_SP = mapScreenSize(SCREEN_MASSIVE); // 4924px

  // Readable string interpolation helper fcts
  const lessThanOrEqual = (screenSize: number) => `(max-width: ${screenSize}px)`;
  const greaterThanOrEqual = (screenSize: number) => `(min-width: ${screenSize}px)`;

  // At tablet breakpoint and below, the right panel assumes 100% of the screen width,
  // so we want to resume regular screen size media query behavior
  const greaterThanAdjustmentThreshold = greaterThanOrEqual(ADJUSTMENT_BREAKPOINT + 1);

  // Consts for reused ranges
  const standardTabletRange = `${greaterThanOrEqual(SCREEN_SM)} and ${lessThanOrEqual(
    SCREEN_MD - 1
  )}`;
  const standardMobileRange = lessThanOrEqual(SCREEN_SM - 1);
  const adjustedMobileRange = `${lessThanOrEqual(
    SCREEN_SM_SP - 1
  )} and ${greaterThanAdjustmentThreshold}`;

  return {
    MOBILE_XS: `@media ${lessThanOrEqual(
      SCREEN_XS_SP - 1
    )} and ${greaterThanAdjustmentThreshold}, ${lessThanOrEqual(SCREEN_XS - 1)}`,
    MOBILE: `@media ${adjustedMobileRange}, ${standardMobileRange}`,
    TABLET: `@media ${greaterThanOrEqual(SCREEN_SM_SP)} and ${lessThanOrEqual(
      SCREEN_MD_SP - 1
    )}, ${standardTabletRange}`,
    DESKTOP: `@media ${greaterThanOrEqual(SCREEN_MD_SP)}`,
    DESKTOP_LG: `@media ${greaterThanOrEqual(SCREEN_LG_SP)}`,
    DESKTOP_XL: `@media ${greaterThanOrEqual(SCREEN_XL_SP)}`,
    DESKTOP_MASSIVE: `@media ${greaterThanOrEqual(SCREEN_MASSIVE_SP)}`,
    EXCLUDE_MOBILE_XS: `@media ${greaterThanOrEqual(SCREEN_XS_SP + 1)}`,
    EXCLUDE_MOBILE: `@media ${greaterThanOrEqual(SCREEN_SM_SP)}, ${standardTabletRange}`,
    EXCLUDE_TABLET: `@media ${greaterThanOrEqual(
      SCREEN_MD_SP
    )}, ${adjustedMobileRange}, ${standardMobileRange}`,
    EXCLUDE_DESKTOP: `@media ${lessThanOrEqual(SCREEN_MD_SP - 1)}`,
    EXCLUDE_DESKTOP_LG: `@media ${lessThanOrEqual(SCREEN_LG_SP - 1)}`,
    EXCLUDE_DESKTOP_XL: `@media ${lessThanOrEqual(SCREEN_XL_SP - 1)}`,
    NOT_MOBILE: `@media ${greaterThanOrEqual(SCREEN_SM_SP)}, ${standardTabletRange}`,
  };
};

export default transformMediaQuery;
