import { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';

const getWindowDimensions = () => {
  const { innerWidth = 0, innerHeight = 0 } = typeof window !== 'undefined' ? window : {};
  return {
    width: innerWidth,
    height: innerHeight,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = _debounce(() => {
      setWindowDimensions(getWindowDimensions());
    }, 500);
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
