import React, { useReducer, createContext, useContext } from 'react';

export type FlyoutPanelContextStateType = {
  isOpen: boolean;
  content?: JSX.Element;
  onClose?: () => void;
};

const initialState: FlyoutPanelContextStateType = {
  isOpen: false,
};

// Actions
const SHOW_FLYOUT_PANEL = 'SHOW_FLYOUT_PANEL';
const HIDE_FLYOUT_PANEL = 'HIDE_FLYOUT_PANEL';

type ShowFlyoutPanelAction = {
  type: typeof SHOW_FLYOUT_PANEL;
  payload: Omit<FlyoutPanelContextStateType, 'isOpen'>;
};
type HideFlyoutPanelAction = {
  type: typeof HIDE_FLYOUT_PANEL;
};

type Action = ShowFlyoutPanelAction | HideFlyoutPanelAction;

function flyoutPanelReducer(
  state: FlyoutPanelContextStateType,
  action: Action
): FlyoutPanelContextStateType {
  switch (action.type) {
    case SHOW_FLYOUT_PANEL: {
      return {
        isOpen: true,
        content: action.payload.content,
        onClose: action.payload.onClose,
      };
    }
    case HIDE_FLYOUT_PANEL: {
      return { isOpen: false, content: undefined, onClose: undefined };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

export type FlyoutPanelContextType = {
  state: FlyoutPanelContextStateType;
  dispatch: (action: Action) => void;
};

const FlyoutPanelContext = createContext<FlyoutPanelContextType | undefined>({
  state: initialState,
  dispatch: () => null,
});

export const FlyoutPanelContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(flyoutPanelReducer, initialState);
  return (
    <FlyoutPanelContext.Provider value={{ state, dispatch }}>
      {children}
    </FlyoutPanelContext.Provider>
  );
};

export const useFlyoutPanelContext = () => {
  const context = useContext(FlyoutPanelContext);
  if (context === undefined) {
    throw new Error('useFlyoutPanelContext must be used within a FlyoutPanelContext');
  }
  const { state, dispatch } = context;

  const showFlyoutPanel = (payload: ShowFlyoutPanelAction['payload']) => {
    return dispatch({ type: SHOW_FLYOUT_PANEL, payload });
  };

  const hideFlyoutPanel = () => {
    return dispatch({ type: HIDE_FLYOUT_PANEL });
  };

  return { state, actions: { showFlyoutPanel, hideFlyoutPanel } };
};
