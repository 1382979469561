import styled from '@emotion/styled';

import { NON_ADJUSTED_RANGE } from 'components/publicWebsiteV2/pageLayouts/SinglePageLayout/helpers/transformMediaQuery';

export const BaseHero = styled.div<{ overrideHeight?: number }>`
  overflow: hidden;
  width: 100%;
  height: 100%;
  height: ${({ overrideHeight }) => (overrideHeight ? `${overrideHeight}px` : '100vh')};
  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    ${({ overrideHeight }) => (overrideHeight ? 'aspect-ratio: 1 / 1' : 'height: 100vw')};
  }
  position: sticky;
  top: 0;
  ${NON_ADJUSTED_RANGE} {
    width: 100%;
    height: ${({ overrideHeight }) => (overrideHeight ? `${overrideHeight}px` : `90vw`)};
    position: relative;
  }
`;

export const Title = styled.div<{
  fontSizeOverride?: {
    desktop: string;
    tablet: string;
    mobile: string;
  };
}>`
  padding: ${props => props.theme.SPACING.XL};
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    padding: ${props => `${props.theme.SPACING.XL} ${props.theme.SPACING.LG}`};
  }
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${({ fontSizeOverride }) =>
    fontSizeOverride?.desktop ? `font-size: ${fontSizeOverride?.desktop};` : ''}
    ${({ fontSizeOverride, theme }) =>
      fontSizeOverride?.tablet
        ? `${theme.MEDIA_QUERY.TABLET} { font-size: ${fontSizeOverride?.tablet}; }`
        : ''}
    ${({ fontSizeOverride, theme }) =>
      fontSizeOverride?.mobile
        ? `${theme.MEDIA_QUERY.MOBILE} { font-size: ${fontSizeOverride?.mobile}; }`
        : ''}

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.2));
`;

export const TitleInnerContainer = styled.span`
  overflow-wrap: break-word;
  width: 100%;
`;
